
import { defineComponent } from 'vue';
import ApiV2 from '@/lib/ApiV2';
import Config from '@/lib/Config';
export default defineComponent({
    data: () => ({
        needSupport: false,
        loading: false,
        supportEmail: Config.get().SUPPORT_EMAIL,
    }),
    watch: {
        $route: {
            immediate: true,
            deep: true,
            async handler(route) {
                try {
                    this.loading = true;
                    await ApiV2.http.post('/api/awsmarketplace/redeem', { marketplaceToken: route.query.token });
                    this.$router.push('/choose');
                } catch {
                    this.needSupport = true;
                } finally {
                    this.loading = false;
                }
            },
        },
    },
});
